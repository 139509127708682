import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CCol,
  CContainer,
  CRow,
  CFormGroup,
  CLabel,
  CInput,
  CForm,
  CBadge,
  CCard,
  CCardBody,
  CCardHeader,
  CSelect,
  CSwitch,
  CCardFooter,
  CCardTitle,
  CButton,
  CCardSubtitle,
  CCardImg,
  CCardText,
  CSpinner,
} from "@coreui/react";
import {
  PARTICIPANT_JOINED_ROUND,
  PARTICIPANT_LEAVED_ROUND,
  LIST_JOINED_PARTICIPANTS,
} from "actions/rounds/types";
import {
  getConferenceData,
  publishActiveCase,
  updateAttendance,
  submitRoundRate,
  switchRoundSpeaker,
  endRound,
  filterCases,
} from "actions/rounds/rounds";
import ZoomComponent from "views/zoom/ZoomComponent";
import {
  ATTENDANCE_TYPE_LEAVING,
  ATTENDANCE_TYPE_ENTERING,
  TYPE_EPISODE,
} from "constants/constants";
import RatingForm from "views/pages/patient/screens/clinic-process/RatingForm";
import { confirmAlert } from "react-confirm-alert";
import i18next from "i18next";
import OrganizerControls from "./OrganizerControls";
import ActiveCase from "./ActiveCase";

const RoundConference = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [caseType, setCaseType] = useState("episode");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [isHost, setIsHost] = useState(false);
  const [endRoundFlag, setEndRoundFlag] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.jwt.user);
  const conferenceRound = useSelector(
    (state) => state.roundsUtilities.conferenceRound
  );
  const zoomCredentials = useSelector(
    (state) => state.roundsUtilities.zoomCredentials
  );
  const roundParticipants = useSelector((state) =>
    state.roundsUtilities.participants
      ? Object.values(state.roundsUtilities.participants)
      : []
  );
  useEffect(() => {
    if (conferenceRound) {
      window.Echo.join(`round.${conferenceRound.id}`)
        .here((users) => {
          dispatch({ type: LIST_JOINED_PARTICIPANTS, payload: users });
        })
        .joining((user) => {
          dispatch({ type: PARTICIPANT_JOINED_ROUND, payload: user });
          dispatch(
            updateAttendance(conferenceRound.id, {
              type: ATTENDANCE_TYPE_ENTERING,
              user_id: user.id,
            })
          );
        })
        .leaving((user) => {
          dispatch({ type: PARTICIPANT_LEAVED_ROUND, payload: user.id });
          dispatch(
            updateAttendance(conferenceRound.id, {
              type: ATTENDANCE_TYPE_LEAVING,
              user_id: user.id,
            })
          );
        })
        .error((error) => {
          console.error("error>>", error);
        });
    }
  }, [dispatch, conferenceRound, loggedInUser]);

  const receiveChannelActiveCase = (eventCaseData) => {
    if (
      eventCaseData.activeCase &&
      eventCaseData.caseType &&
      eventCaseData.selectedPatient
    ) {
      setSelectedCase(eventCaseData.activeCase);
      setCaseType(eventCaseData.caseType);
      setSelectedPatient(eventCaseData.selectedPatient);
    }
  };

  if (conferenceRound) {
    window.Echo.private(`round.${conferenceRound.id}`).listen(
      "ActiveCase",
      (eventData) => receiveChannelActiveCase(eventData.caseData)
    );
  }

  if (conferenceRound) {
    window.Echo.private(`round.${conferenceRound.id}`).listen(
      "RoundSwitchSpeaker",
      (eventData) => {
        setIsHost(Number(eventData.speaker_id) === Number(loggedInUser.id));
        setSelectedPatient(null);
        setSelectedCase(null);
        setCaseType("episode");
      }
    );
  }

  if (conferenceRound) {
    window.Echo.private(`round.${conferenceRound.id}`).listen("EndRound", () =>
      setEndRoundFlag(true)
    );
  }

  useEffect(() => {
    dispatch(getConferenceData(match.params.sessionToken));
  }, [dispatch, match]);

  useEffect(() => {
    if (conferenceRound) {
      setIsHost(conferenceRound.organizer_id === loggedInUser.id);
      if (conferenceRound.shared_data) {
        const sharedData = JSON.parse(conferenceRound.shared_data);
        setSelectedPatient(sharedData.selectedPatient);
        setCaseType(sharedData.caseType);
        setSelectedCase(sharedData.activeCase);
      }
    }
  }, [conferenceRound, loggedInUser]);

  const onSelectCase = (caseValue) => {
    setSelectedCase(caseValue);
    dispatch(
      publishActiveCase(conferenceRound.id, {
        activeCase: caseValue,
        caseType,
        selectedPatient,
      })
    );
  };
  const onSelectPatient = (patientValue) => {
    setSelectedPatient(patientValue);
    setSelectedCase(null);
    dispatch(filterCases(caseType, patientValue.id, conferenceRound.id));
  };

  const onSelectCaseType = (event) => {
    setCaseType(event.target.value);
    if(selectedPatient){
      dispatch(filterCases(event.target.value, selectedPatient.id, conferenceRound.id));
    }
  };

  const submitRate = (formData) => {
    dispatch(submitRoundRate(formData));
  };

  if (endRoundFlag) {
    confirmAlert({
      title: `{i18next.t("Round Is Finished")}`,
      customUI: ({ onClose }) => (
        <RatingForm
          onClose={onClose}
          model_id={conferenceRound.id}
          submitRate={submitRate}
          message={i18next.t(
            "The Round Is Finished, Thanks For using our System."
          )}
        />
      ),
      afterClose: () => history.push("/rounds/my-rounds"),
    });
  }
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);

  const availableConsultants = roundParticipants
    ? roundParticipants.filter((item) =>
        conferenceRound.consultantsIds.includes(item.id)
      )
    : [];

  const renderSpeakerOption = () =>
    availableConsultants.map((item) => (
      <option value={item.id}>{item.first_name}</option>
    ));

  const onSwitchSpeaker = () => {
    if (selectedSpeaker) {
      confirmAlert({
        title: `${i18next.t("Are you sure?")}`,
        message: `${i18next.t(
          "You Want To Switch Controls To Another Presenter."
        )}`,
        buttons: [
          {
            label: `${i18next.t("Yes")}`,
            onClick: () => {
              dispatch(
                switchRoundSpeaker(conferenceRound.id, {
                  speaker_id: selectedSpeaker,
                })
              );
            },
          },
          {
            label: `${i18next.t("No")}`,
          },
        ],
      });
    }
  };

  const onEndRound = () => {
    confirmAlert({
      title: `${i18next.t("Are You Sure You Want to End The Round?")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(endRound(conferenceRound.id));
          },
        },
        {
          label: `${i18next.t("No")}`,
        },
      ],
    });
  };

  const renderSwitchSpeakerOptions = () => {
    if (availableConsultants.length > 1) {
      return (
        <>
          <CRow style={{ marginTop: "30px" }}>
            <CCol md="8">
              <CLabel>{i18next.t("Available Presenters")}:</CLabel>
              <CSelect
                value={selectedSpeaker}
                onChange={(event) => setSelectedSpeaker(event.target.value)}
              >
                <option value={0} selected disabled>
                  {i18next.t("Select Presenter")}
                </option>
                {renderSpeakerOption()}
              </CSelect>
            </CCol>
          </CRow>
          <CRow>
          <CCol md="2" className="mt-2">
              <CButton
                className="btn-danger"
                style={{ paddingRight: "7px" }}
                onClick={onSwitchSpeaker}
              >
                Switch
              </CButton>
            </CCol>
          </CRow>
          <br />
        </>
      );
    }
    return (
      <span>
        {i18next.t("You Are The Only Online Organizer of this Round")}.
      </span>
    );
  };

  return conferenceRound ? (
    <CContainer>
      <CRow className="justify-content-between align-items-center my-1">
        <CCol>
          <div className="float-left table-title pb-3">
            {i18next.t("Round Info")}
          </div>
        </CCol>
      </CRow>
      <CRow>
        {/*  vedio section */}
        <CCol xs="12" md="8" className="round-video m-0 p-0">
          {/* <CButton className="btn btn-primary btn-lg " style={{ textAlign: "center", borderRadius: "20px", boxShadow: "2px 16px 6px #35488d;", backgroundColor: "#35488D", width: "20%", boxShadow: "0px 2px 3px blue" }}>JOIN MEETING</CButton> */}

          {isHost && (
            <OrganizerControls
              onSelectCaseType={onSelectCaseType}
              caseType={caseType}
              onSelectPatient={onSelectPatient}
              selectedPatient={selectedPatient}
              onSelectCase={onSelectCase}
              selectedCase={selectedCase}
            />
          )}
          {!isHost && selectedCase && selectedPatient && (
            <ActiveCase
              activeCase={selectedCase}
              caseType={caseType}
              patient={selectedPatient}
            />
          )}
        </CCol>

        {/* slidebar section -1  */}
        <CCol
          xs="12"
          md="4"
          style={{ height: "auto", width: "auto" }}
          className="m-0 p-0"
        >
          <div className="sticky-top">
            <CCol xs="12" className="round-video mb-3">
              {conferenceRound && conferenceRound.zoom_meeting_id && (
                <ZoomComponent
                  meetingNumber={conferenceRound.zoom_meeting_id}
                  passWord={conferenceRound.zoom_meeting_password}
                  userName={`${loggedInUser.first_name} ${loggedInUser.last_name}`}
                  role={conferenceRound.organizer_id == loggedInUser.id ? 1 : 0}
                  zoomCredentials={zoomCredentials}
                />
              )}
            </CCol>
            {conferenceRound.organizer_id === loggedInUser.id &&
              <CCol>
                <CButton
                  className="btn btn-danger mb-5 text-center item-align-center w-full"
                  style={{
                    boxShadow: "0px 2px 6px red",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                  onClick={onEndRound}
                >
                  End Round
                </CButton>
              </CCol>
            }
            <CCol xs="12" className="round-info">
              <CCard className="border-0 mb-0">
                <CCardTitle>
                  {" "}
                  <h3
                    className="text-left font-bold m-0 mt-2 "
                    style={{
                      fontSize: "x-large",
                      opacity: "1",
                      color: "#35488D",
                      fontWeight: "530",
                    }}
                  >
                    {i18next.t("Round Title")} : {conferenceRound.title}
                  </h3>
                </CCardTitle>
                {/* <CCardSubtitle className="text-left" style={{ fontSize: "medium", opacity: "1", color: "#35488D", letterSpacing: "0px", marginTop: "14px" }}>The impact of phisic round on the attiude of medical students towwards phyisic as and career</CCardSubtitle> */}
                <CRow className="mt-3">
                  <CCol xs="3" md="2">
                    <CCardImg
                      src="https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_960_720.png"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginTop: "7px",
                        objectFit: "cover",
                      }}
                    />
                  </CCol>
                  <CCol xs="9" md="10">
                    <div
                      style={{
                        paddingLeft: "14px",
                        paddingBottom: "10px",
                        fontSize: "15px",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {conferenceRound.organizer_id === loggedInUser.id &&
                        renderSwitchSpeakerOptions()}
                    </div>

                    {/*
                    <h3 className="font-weight-bold" style={{ paddingLeft: "14px", fontSize: "15px", textAlign: "left" }}>Ahmed shaban</h3>
                    <p style={{ paddingLeft: "14px", fontSize: "15px", textAlign: "left" }}>Oginizer</p> */}
                    <h3
                      className="font-weight-bold"
                      style={{
                        paddingLeft: "14px",
                        fontSize: "15px",
                        textAlign: "left",
                        marginBottom: "1.5em",
                      }}
                    >
                      {i18next.t("Time")}
                    </h3>
                    <p
                      style={{
                        paddingLeft: "14px",
                        fontSize: "15px",
                        textAlign: "left",
                      }}
                    >
                      {" "}
                      {`${i18next.t("From")}  ${
                        conferenceRound.from
                      } ${i18next.t("To")} ${conferenceRound.to}`}
                    </p>
                  </CCol>
                </CRow>
                {/* <CRow> */}
                {/* <CCol> */}

                {/* <CButton className="btn btn-danger mb-5 text-center item-align-center" style={{ boxShadow: "0px 2px 6px red", borderRadius: "20px", width: "142px" }} >END ROUND</CButton> */}
                {/* </CCol> */}
                {/* </CRow> */}
              </CCard>
            </CCol>

            {/* slidebar section -2  */}
            <CCol xs="12" className="participants">
              <CCardTitle>
                <h3
                  style={{
                    fontWeight: "530",
                    textAlign: "left",
                    color: "#35488D",
                  }}
                >
                  {i18next.t("Participants")}
                </h3>
              </CCardTitle>
              <CRow>
                <CCol xs="3" md="2">
                  <CCardImg
                    src="https://cdn.pixabay.com/photo/2018/08/28/12/41/avatar-3637425_960_720.png"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      marginTop: "7px",
                      objectFit: "cover",
                    }}
                  />
                </CCol>
                <CCol xs="9" md="10">
                  <CCardTitle>
                    <h3
                      style={{
                        fontWeight: "500",
                        textAlign: "left",
                        color: "#35488D",
                        marginTop: "7%",
                      }}
                    >
                      <span>{`${i18next.t("Participants")} : ${
                        roundParticipants ? roundParticipants.length : 0
                      }`}</span>
                    </h3>
                  </CCardTitle>
                </CCol>
                {/* <CCol >
                  <CButton class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                </CCol> */}
              </CRow>
              <CRow>
                <CCol>
                  {/* <CButton className="btn btn-primary btn-lg" style={{ textAlign: "center", borderRadius: "20px", boxShadow: "0px 3px 3px #0000003E;", backgroundColor: "#35488D", width: "40%" }}>SWITCH</CButton> */}
                </CCol>
              </CRow>
            </CCol>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  ) : (
    <CSpinner className="spinner" variant="grow" />
  );
};

export default RoundConference;
