import React, { useEffect, useState ,useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import {
  CCol,
  CRow,
  CCard,
  CCardBody,
  CLabel,
  CInputCheckbox,
  CFormGroup,
} from "@coreui/react";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import {
  fetchIndexUtilities,
  fetchEvents,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  fetchClinicsEvents,
} from "actions/calendar/events";
import { fetchRoundsCalendar } from "actions/rounds/rounds";
import EventForm from "./EventForm";
import __ from 'i18next';

// const ColoredDateCellWrapper = ({ children }) =>
//   React.cloneElement(React.Children.only(children), {
//     style: {
//       backgroundColor: "lightblue",
//     },
//   });

const CalendarView = () => {
  const localizer = momentLocalizer(moment);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [slotData, setSlotData] = useState(false);
  const [hospital, setHosptial] = useState(false);
  const [specialty, setSpecialty] = useState(false);
  const [clinic, setClinic] = useState({ id: 0, title: "all" });
  const [personalOnly, setPersonalOnly] = useState(0);
  const [editedEvent, setEditedEvent] = useState(false);
  const allowedViews = [Views.DAY, Views.MONTH, Views.WEEK, Views.AGENDA];
  const history = useHistory();
  const [currentDate, setCurrentDate] = useState(new Date());

  const { hospitals, specialties, clinics } = useSelector(
    (state) => state.calendarUtilities
  );
  const calendarEvents = useSelector((state) => state.calendarEvents);
  const saveSuccess = useSelector((state) => state.calendarEvents?.saveSuccess);
  const rounds = useSelector((state) => state.rounds.data);
  const clinicsEvents = useSelector((state) =>
    state.calendarUtilities.clinics_events
      ? state.calendarUtilities.clinics_events
      : []
  );
  const messages = {
    month: __.t('Month'),
    day: __.t('Day'),
    week: __.t('Week'),
    agenda: __.t('Agenda'),
    next: __.t('Next'),
    previous: __.t('Previous'),
    today: __.t('Today'),
    date: __.t('date'),
    time: __.t('Time'),
    event: __.t('Event'),
    AM: __.t('AM'),
    PM: __.t('PM'),
    allDay: __.t('All Day'),
    noEventsInRange: __.t('There are no events in this range'),
    showMore: total => `+${total} ${__.t('More')}`,
  }
 function fetchAllData(hospital,specialty,date){

  if (hospital) {
    setHosptial(hospital);
  }
  if (specialty) {
    setSpecialty(specialty);
  }
  if (hospital && specialty) {
    dispatch(fetchEvents(hospital.id, specialty.id, personalOnly,date));
  }
  if (personalOnly) {
    const clinicId = clinic ? clinic.id : 0;
    dispatch(fetchClinicsEvents(clinicId,date));
  }
  dispatch(fetchRoundsCalendar(date));

}
  useEffect(() => {
    if (!hospitals || !specialties) {
      dispatch(fetchIndexUtilities());
    }
    const firstHospital = hospitals  ? hospitals[0] : null;
    const firstSpecialty = specialties ? specialties[0] : null;
    fetchAllData(firstHospital,firstSpecialty,currentDate)
  }, [hospitals, specialties]);

  const toggleModal = () => setModal(!modal);

  const submitEdit = (data) => {
    dispatch(
      updateCalendarEvent(editedEvent.id, {
        ...data,
        start: moment(data.start).format("YYYY-MM-DD HH:mm"),
        end: moment(data.end).format("YYYY-MM-DD HH:mm"),
        doctors: data.doctors ? data.doctors.map((item) => item.id) : [],
      })
    );
  };
  const onNavigate = newDate => {
    const navDate = new Date(newDate.getFullYear(), newDate.getMonth(),newDate.getDate());
    setCurrentDate(navDate)
    fetchAllData(hospital,specialty,navDate)

  }
  const onSubmit = (data) => {
    if (editedEvent) {
      submitEdit(data);
    } else {
      dispatch(
        createCalendarEvent({
          ...data,
          start: moment(data.start).format("YYYY-MM-DD HH:mm"),
          end: moment(data.end).format("YYYY-MM-DD HH:mm"),
          doctors: data.doctors.map((item) => item.id),
        })
      );
    }
    toggleModal();
  };

  useEffect(() => {
    if (hospital && specialty && saveSuccess) {
      dispatch(
        fetchEvents(hospital.id, specialty.id, personalOnly, currentDate)
      );
    }
  },[saveSuccess])


  const handleDeleteEvent = (eventId) => {
    confirmAlert({
      title: `${i18next.t("Are you sure?")}`,
      message: `${i18next.t("You Want To Delete This Event")}`,
      buttons: [
        {
          label: `${i18next.t("Yes")}`,
          onClick: () => {
            dispatch(deleteCalendarEvent(eventId));
            toggleModal();
          },
        },
        {
          label: `${i18next.t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const onHospitalChange = (value) => {
    setHosptial(value);
    if (specialty) {
      dispatch(fetchEvents(value.id, specialty.id, personalOnly,currentDate));
    }
  };

  const onSpecialtyChange = (value) => {
    setSpecialty(value);
    if (hospital) {
      dispatch(fetchEvents(hospital.id, value.id, personalOnly,currentDate));
    }
  };

  const onClinicChange = (value) => {
    dispatch(fetchClinicsEvents(value.id,currentDate));
    setClinic(value);
  };

  const onClickPersonal = (event) => {
    const checkedVal = event.target.checked;
    const personalEvent = checkedVal ? 1 : 0;
    setPersonalOnly(personalEvent);
    if (hospital && specialty) {
      dispatch(
        fetchEvents(hospital.id, specialty.id, personalEvent, currentDate)
      );
    }
    if (checkedVal) {
      const clinicId = clinic ? clinic.id : 0;
      dispatch(fetchClinicsEvents(clinicId,currentDate));
    }
  };

  const handleSelectEvent = (event) => {
    if (event.type === "round") {
      history.push(`/rounds/my-rounds/${event.round_id}/details`);
    } else if (event.type === "clinic") {
      history.push(
        `/clinics/my-clinics/${event.clinic_id}/appointments/${event.date}`
      );
    } else {
      setEditedEvent(event);
      toggleModal();
    }
  };

  const handleSelectSlot = ({ start, end }) => {
    setEditedEvent(false);
    setSlotData({ start, end });
    toggleModal();
  };

  const prepareCalendarEvent = () => {
    const result = Object.values(calendarEvents) || [];
    let events = result.map((item) => ({
      ...item,
      start: new Date(item.start),
      end: new Date(item.end),
    }));
    if (rounds) {
      const roundEvents = (rounds).map((item) => ({
        ...item,
        title: `${i18next.t("Round")}: ${item.title}`,
        start: new Date(item.start),
        end: new Date(item.end),
        backgroundColor: "orange",
        type: "round",
        round_id: item.id,
      }));
      let preparedClinicEvents = [];
      if (personalOnly) {
        preparedClinicEvents = clinicsEvents.map((item) => ({
          ...item,
          start: new Date(item.start),
          end: new Date(item.end),
          backgroundColor: "Green",
          type: "clinic",
          clinic_id: item.clinic_id,
          date: item.date,
        }));
      }
      events = [...events, ...roundEvents, ...preparedClinicEvents];
    }
    return events;
  };

  const renderSecondFilter = () => {
    if (personalOnly) {
      return (
        <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
          <CLabel htmlFor="clinic" className="labels">
            Clinic
          </CLabel>
          <Select
            id="clinic"
            name="clinic"
            cacheOptions
            value={clinic}
            options={clinics ? [{ id: 0, title: "all" }, ...clinics] : []}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            onChange={onClinicChange}
          />
        </CCol>
      );
    }
    return (
      <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
        <CLabel htmlFor="specialty" className="labels">
          {i18next.t("Specialty")}
        </CLabel>
        <Select
          id="specialty"
          name="specialty"
          cacheOptions
          value={specialty}
          options={specialties || []}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          onChange={onSpecialtyChange}
        />
      </CCol>
    );
  };

  return (
    <>
      <CRow>
        <CCol>
          <div className="float-left table-label-title">{i18next.t("calendar")}</div>
        </CCol>
      </CRow>
      <CCol>
        <CCard>
          <CCardBody>
            <CFormGroup row>
              <CCol xs={12} sm={12} md={4} lg={4} xl={4}>
                <CLabel htmlFor="hospital" className="labels">
                  {i18next.t("Location")}
                </CLabel>
                <Select
                  id="hospital"
                  name="hospital"
                  isDisabled={personalOnly}
                  cacheOptions
                  value={hospital}
                  options={hospitals || []}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  onChange={onHospitalChange}
                />
              </CCol>
              {renderSecondFilter()}
              <CCol xs={12} sm={12} md={3} lg={3} xl={3}>
                <CFormGroup
                  variant="custom-checkbox"
                  style={{ marginTop: "11%" }}
                  inline
                >
                  <CInputCheckbox
                    custom
                    id="personal"
                    name="personal"
                    onClick={onClickPersonal}
                  />
                  <CLabel
                    variant="custom-checkbox"
                    className="labels"
                    htmlFor="personal"
                  >
                    {i18next.t("Show Personal Event only")}.
                  </CLabel>
                </CFormGroup>
              </CCol>
            </CFormGroup>
            <hr />
            <CRow>
              {hospital && (
                <Calendar
                  events={prepareCalendarEvent()}
                  localizer={localizer}
                  views={allowedViews}
                  step={60}
                  showMultiDayTimes
                  style={{ height: "40em", width: "100%" }}
                  selectable
                  defaultView={Views.MONTH}
                  scrollToTime={new Date(1970, 1, 1, 6)}
                  defaultDate={new Date()}
                  onSelectEvent={handleSelectEvent}
                  onSelectSlot={handleSelectSlot}
                  eventPropGetter={(event) => ({
                    style: { backgroundColor: event.backgroundColor },
                  })}
                  date={currentDate}
                  onNavigate={onNavigate}
                  messages={messages}
                  id="avh-calender"
                />
              )}
            </CRow>
            {modal && (
              <EventForm
                editedEvent={editedEvent}
                onSubmit={onSubmit}
                onCancel={toggleModal}
                modal={modal}
                FormTitle={editedEvent ? `${i18next.t("Edit Event")}` : `${i18next.t("Create New Event")}`}
                hospital={hospital}
                specialty={specialty}
                start={slotData ? slotData.start : new Date()}
                end={slotData ? slotData.end : new Date()}
                handleDeleteEvent={handleDeleteEvent}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </>
  );
};

export default CalendarView;
