import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { fetchClinicUtilities, createClinic } from "actions/clinics/clinics";
import { fetchDoctors, clearDoctorsList } from "actions/doctors/doctors";
import {
  fetchCoordinators,
  clearCoordinatorsList,
} from "actions/coordinators/coordinators";
import { removeEmptyProperties, transformData } from "helpers/helpers";
import ClinicForm from "../form/ClinicForm";
import i18next from "i18next";

/**
 * @returns {*} clinic form
 * @class
 */
function CreateClinic() {
  const history = useHistory();
  const label = `${i18next.t("Add New Clinic")}`;
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({});
  const utilities = useSelector((state) => state.clinicsUtilities);
  const doctors = useSelector((state) => state.doctors);
  const coordinators = useSelector((state) => state.coordinators);
  const redirect = useSelector((state) => state.clinics.redirect);
  const onSubmit = async (formData) => {
    const data = {
      ...removeEmptyProperties(formData),
      start_date: format(formData.start_date, "Y-MM-d"),
      end_date: formData.end_date ? format(formData.end_date, "Y-MM-d") : null,
      coordinators: JSON.stringify(transformData(formData.coordinators)),
      title: formData.title,
      time_zone: formData.time_zone.value
        ? formData.time_zone.value
        : formData.time_zone,
    };
    await dispatch(createClinic(data));
  };
  console.log(filters);
  const handleSpecialtyChange = (event) => {
    setFilters({ ...filters, specialty: event.target.value });
    dispatch(fetchDoctors({ ...filters, specialty: event.target.value }));
  };

  const handleLevelChange = (event) => {
    setFilters({ ...filters, level: event.target.value });
    dispatch(fetchDoctors({ ...filters, level: event.target.value }));
  };

  const handleSpokeChange = (event) => {
    setFilters({ ...filters, hospital: event.target.value });
    dispatch(fetchDoctors({ ...filters, hospital: event.target.value }));
    dispatch(fetchCoordinators({ hospital: event.target.value }));
  };

  if (redirect) {
    history.push("/clinics");
  }

  useEffect(() => {
    dispatch(fetchClinicUtilities());
    dispatch(clearDoctorsList());
    dispatch(clearCoordinatorsList());
  }, [dispatch]);

  return (
      <ClinicForm
        onSubmit={onSubmit}
        levels={utilities.levels || []}
        specialties={utilities.specialties || []}
        time_units={utilities.time_units || []}
        hospitals={utilities.hospitals || []}
        label={label}
        handleSpecialtyChange={handleSpecialtyChange}
        handleLevelChange={handleLevelChange}
        handleSpokeChange={handleSpokeChange}
        doctors={doctors.data || []}
        coordinators={coordinators.data || []}
      />
    );
}

export default CreateClinic;
